import React from "react"
import Card from "./Card"

import Image1 from "../../images/Brainstormingsession_Outline.svg"
import Image2 from "../../images/Time_Outline.svg"
import Image3 from "../../images/Winners_Outline.svg"
import { StaticImage } from "gatsby-plugin-image"

const QuickLinks = () => {
  return (
    <div className="">
      <div className="prose">
        <h1>Rychlé odkazy</h1>
      </div>
      <div className="my-8 grid grid-cols-2 gap-5 md:grid-cols-4 md:gap-10">
        <div className="card cursor-pointer bg-base-200 shadow-md transition-all hover:scale-95 hover:shadow-sm">
          <div className="card-body flex items-center justify-center">
            <StaticImage alt="" className="w-52" src="../../images/Time.png" placeholder="blurred"/>
            <span className="text-center text-2xl">Jídelna</span>
          </div>
        </div>
        <div className="card cursor-pointer bg-base-200 shadow-md transition-all hover:scale-95 hover:shadow-sm">
          <div className="card-body flex items-center justify-center">
            <StaticImage
              alt=""
              className="w-52"
              src="../../images/Calendar.png"
              placeholder="blurred"
            />
            <span className="text-center text-2xl">Rozvrh hodin</span>
          </div>
        </div>
        <div className="card cursor-pointer bg-base-200 shadow-md transition-all hover:scale-95 hover:shadow-sm">
          <div className="card-body flex items-center justify-center">
            <StaticImage
              alt=""
              className="w-52"
              src="../../images/Photo camera.png"
              placeholder="blurred"
            />
            <span className="text-center text-2xl">Poslední akce</span>
          </div>
        </div>
        <div className="card cursor-pointer bg-base-200 shadow-md transition-all hover:scale-95 hover:shadow-sm">
          <div className="card-body flex items-center justify-center">
            <StaticImage alt="" className="w-52" src="../../images/World.png" placeholder="blurred"/>
            <span className="text-center text-2xl">Bakaláři</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuickLinks
