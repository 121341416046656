import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const LandigPageZero = () => {
  return (
    <div className="max-h-screen">
      <div className="my-20 flex flex-col items-center justify-between px-5 md:flex-row">
        <div className="py-6 md:w-2/5">
          <h1 className="relative text-5xl md:text-6xl">
            <span className="absolute -z-10 -mt-8 -ml-8 block h-32 w-32 rounded-full bg-base-200"></span>
            Vítejte na stránkách{" "}
            <span className="mb-4 leading-normal text-primary ">
              základní školy
            </span>
          </h1>
          <p className="py-4 text-xl leading-normal md:text-2xl">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque ut
            voluptatem vero. Nam temporibus pariatur qui? Quo eaque adipisci
            velit, deserunt iusto facere labore, cupiditate earum voluptate
            consequatur, quisquam ratione!
          </p>
        </div>
        <div className="relative flex justify-end p-10 md:w-3/5">
          <div className="absolute -mr-32 aspect-square h-full rounded-full bg-base-200"></div>
          <StaticImage
            className="h-full "
            src="../../../images/class_debat.png"
            alt="Hlavní ilustrace "
            placeholder="blurred"
          />
        </div>
      </div>
    </div>
  )
}

export default LandigPageZero
