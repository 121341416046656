import * as React from "react"
import LastEvents from "../components/Events/LastEvents"
import UpcomingEvents from "../components/Events/FutureEvents"
import { Layout } from "../components/Layout/Layout"
import QuickLinks from "../components/QucikLinks/QuickLinks"
import LandigPageZero from "../components/Layout/LandingPages/LandigPageZero"

// markup
const IndexPage = () => {
  return (
    <>
      <Layout activePage={{ title: "", slug: "/" }}>
        <div className="content">
          <LandigPageZero />
          <div className="my-8">
            <QuickLinks />
          </div>
          <div className="my-20 grid justify-between gap-y-5 md:grid-cols-3 md:gap-10">
            <div className="col-span-2">
              <LastEvents />
            </div>
            <div className="col-span-1">
              <UpcomingEvents />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
