import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import EventBlock from "./EventBlock"
import { StrapiEvent } from "../../interfaces/Event"

const LastEvents = () => {
  const lastEventsQuery = useStaticQuery(graphql`
    query lastEventsQuery {
      allStrapiPastEvent(sort: { order: ASC, fields: from }, limit: 10) {
        nodes {
          ...STRAPI_PAST_EVENTFragment
        }
      }
    }
  `)

  const eventList = lastEventsQuery.allStrapiPastEvent.nodes
  return (
    <div className=" w-full">
      <div className="prose ">
        <h1>Poslední akce</h1>
      </div>
      <div className="my-8 grid  grow-0 gap-5 md:grid-cols-2 md:gap-10 xl:grid-cols-3">
        {eventList.map((event: StrapiEvent) => {
          return (
            <EventBlock
              key={`lastEvent-${event.slug}`}
              event={event}
              url="akce"
            />
          )
        })}
      </div>
    </div>
  )
}

export default LastEvents
